import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { FiltersObjectData, FiltersData } from '@Types/Filters';

export interface Params {
  filters: FiltersObjectData;
}

export interface Response {
  productsTotalCount: number;
  filters: FiltersData;
}

async function getFavoritesProductsCount(params: Params) {
  const { filters } = params;

  const url = '/backend/favorites/get-products-count';
  const res = await Api.queryProxi<ApiResponse<Response>>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(filters),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getFavoritesProductsCount;
