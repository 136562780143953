import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { FavoritesData } from '@Types/Favorites';

export interface Params {
  page: number | string;
  search?: string;
}

export interface Result {
  ok: boolean;
  data: FavoritesData;
}

async function getFavoritesProducts({ page, search = '' }: Params) {
  const searchParams = new URLSearchParams(search);
  const pageWithDefault = Number(page || 1);

  searchParams.set('page', pageWithDefault.toString());

  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : searchString;
  const url = `/backend/favorites/get-products${qs}`;
  const res = await Api.queryProxi<Result>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getFavoritesProducts;
