import { useMutation } from '@tanstack/react-query';

import ApiSite from '@Api/Site';
import * as ApiCategory from '@Api/Category';
import * as ApiFavorites from '@Api/Favorites';
import * as CatalogFiltrator from '@Stores/CatalogFiltrator';
import useSearchParams from '@Hooks/useSearchParams';

import type { FiltersData } from '@Types/Filters';

interface Response {
  productsTotalCount: number;
  filters: FiltersData;
}

export const useSelectFilter = (slug: string) => {
  const categories = CatalogFiltrator.useActiveCategoryIds();
  const searchParams = useSearchParams();
  const keys = ['select-filter', slug, categories];

  const { mutateAsync, isLoading } = useMutation(
    keys,
    async () => {
      let res: Response;
      const filters = CatalogFiltrator.formatFiltersToObject();

      if (slug === 'search') {
        res = await ApiSite.getSearchProductsCount({
          filters,
          search: searchParams,
        });
      } else if (slug === 'favorites') {
        res = await ApiFavorites.getFavoritesProductsCount({
          filters,
        });
      } else {
        res = await ApiCategory.selectFilter({
          slug,
          filters,
          categories,
        });
      }

      return res;
    },
    {
      onSuccess: (res) => {
        CatalogFiltrator.changeCount({ ...res.filters, totalCount: res.productsTotalCount });
      },
    },
  );

  return {
    selectFilter: mutateAsync,
    isLoading,
  };
};

export default useSelectFilter;
